<template>
    <div class="mainContent" style="min-height:400px;">
        <div class="flex flex-column w-full mb-3">
            <Breadcrumb :home="home" :model="props.breadcrumb"
                style="background: transparent !important; ">
                <template #item="{item}">
                    <routerLink v-if="item.route" v-slot="{ navigate }" :to="item.route" custom >
                        <a @click="navigate">
                            <span >{{ item.label }}</span>
                        </a>
                    </routerLink>
                </template>
            </Breadcrumb>
        </div>
        <ConDataTable tableId="OrderList" :title="props.dataTableTitle" :columns="props.tableColumns" class="w-full mb-5"
            :expansionColumns="props.tableExpansionColumns" filterDisplay="menu" :rowsPerPageOptions="[10, 20, 50]"
            :onReload="waitForCompany" @onSelectionChanged="onSelectionChange" ref="tableRef"
            :expansionKey="props.expansionKey" :calendarProps="calendarProps" filterType="odata">
            <!-- :createActionMenu="createVisitActionMenu"> -->

        </ConDataTable>
    </div>
</template>
<script setup lang="ts">
import { ConDataTable, type SieveCallback } from '@congatec/primevue-components-lib';
import { ref } from 'vue';
import Breadcrumb from 'primevue/breadcrumb';
import { type CalendarProps } from '@congatec/primevue-components-lib';
import { useRouter } from 'vue-router';
import { to } from '@congatec/primevue-components-lib';
import { useCompanyStore } from '@/stores/company-store';
import { sleepAsync } from '@/shared/sleep';
const router = useRouter();

const companyStore = useCompanyStore();

companyStore.$subscribe(() => {
  tableRef.value?.forceReload();
});

//TODO: tableId= 
//TODO: implement missing props when the sap enpoint is implemented
const props = withDefaults(defineProps<{
    dataTableTitle: string,
    breadcrumb: Array<BreadcrumbItems>,
    expansionKey: string,
    tableColumns: Array<TableColumns>,
    tableExpansionColumns: Array<TableExpansionColumns>,
    fetchDataAsync: SieveCallback<any>,
    calendarProps: CalendarProps
}>(), {
    calendarProps: () => {
        return {
            dateFormat: 'dd.M.yy',
            hourForamt: '12',
            selectionMode: 'range',
            inline: true,
            filterField: 'deliveryTime'
        }
    }
});



const home = ref({
    label: 'Home',
    route: '/',
})

// const calendarProps = ref<CalendarProps>({
//     dateFormat: 'dd.M.yy',
//     hourForamt: '12',
//     selectionMode: 'range',
//     inline: true,
//     filterField: 'deliveryTime'
// });
type BreadcrumbItems = {
    label: string,
    route: string,
}

type TableColumns = {
    expander?: boolean,
    selectionMode?: string;
    header?: string,
    dataType?: string,
    isFiltered?: boolean,
    sortable?: boolean,
    field: string,
}

type TableExpansionColumns = {
    field: string,
    header: string,
    dataType: string
}

const tableRef = ref<null | typeof ConDataTable>(null);

//functions area
function onSelectionChange(selectedRows: any) {
}

// Create our number formatter.


const waitForCompany = async (filters: string, sorts: string, page: number, rows: number) => {
  while (companyStore.selectedCompany == undefined) {
    console.info("Waiting for company id selection...");
    await sleepAsync(100);
  }
  return await props.fetchDataAsync(filters, sorts, page, rows);
}

</script>
<style scoped>
a {
    cursor:pointer;
}


</style>
