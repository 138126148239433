<template>
    <div class="py-2 ">
        <div class="flex flex-column w-full">
            <Breadcrumb :home="{ label: 'Home', command: to('/') }" style="background: transparent !important;"
                class="p-0">
            </Breadcrumb>
        </div>
        <div class="text-900 font-bold text-6xl mb-4 text-center">Customer & Partner Portal</div>
        <div class="text-700 text-xl mb-6 text-center line-height-3">Welcome to the congatec Unified Information
            Platform. <br /> From here you can consult and manage your past and on-going operations, as well as contact
            us.</div>

        <div class="grid">
            <div :class="`col-11 lg:col-${card.gridWidth}`" v-for="card in cards" v-bind:key="card.title" >
                <div :class="{'shadow-2':true, 'h-full':true, 'flex':true, 'flex-column':true, 'surface-card':true, 'disabled-card':!card.available}" style="border-radius: 6px; background-color: #f2f2f2 !important">
                    <div id="cardTitle">
                        <div
                            :style="`background-image: url(${card.bgImgUrl}); background-size: cover; background-position: center; height:130px; border-radius:6px 6px 0 0;`">
                        </div>
                        <div class="text-900 font-medium text-xl mb-2 text-center mt-2">{{ card.title }}</div>
                        <div class="text-600 text-center">{{ card.description }}</div>
                    </div>
                    <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
                    <div id="cardContent" class="px-3 flex flex-column" v-for="(arrays, i) in card.items"
                        v-bind:key="i">
                        <div class="flex flex-row justify-content-center gap-5">
                            <ul class="list-disc flex-column p-0 m-0 flex-grow-1 w-6">
                                <li class="flex align-items-center justify-content-start mb-3"
                                    v-for="(item, index) in arrays.filter((item, index) => { return index % 2 == 0 })"
                                    v-bind:key="index">
                                    <i v-if="item.available == true" class="pi pi-check-circle text-green-500 mr-2"></i>
                                    <i v-else class="pi pi-times-circle text-red-500 mr-2"></i>
                                    {{ item.title }}
                                </li>
                            </ul>

                            <ul class="list-disc flex-column p-0 m-0 flex-grow-1 w-6 ">
                                <li class="flex align-items-center justify-content-end mb-3 "
                                    v-for="(item, index) in arrays.filter((item, index) => { return index % 2 != 0 })"
                                    v-bind:key="index">
                                    <p class=" w-full text-right">{{ item.title }}</p>
                                    <i v-if="item.available == true" class="pi pi-check-circle text-green-500 mr-2"></i>
                                    <i v-else class="pi pi-times-circle text-red-500 ml-2"></i>
                                </li>
                            </ul>

                        </div>
                        <hr class="my-2 border-top-1 border-none surface-border mt-auto"
                            v-if="i < card.items.length - 1" />
                    </div>
                    <hr class="mx-0 border-none surface-border mt-auto" />
                    <div id="cardFooter">

                        <Button icon="pi pi-arrow-right" label="More" iconPos="right"
                            class="p-3 w-full text-lg Card-Button" @click="router.push(card.routerLink)"></Button>
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>
<script setup lang="ts">
import Button from 'primevue/button';
import Breadcrumb from 'primevue/breadcrumb';
import { useRouter } from 'vue-router';
import { to } from '@congatec/primevue-components-lib';
import { onMounted, ref } from 'vue';
import Divider from 'primevue/divider';

const router = useRouter()
const cards = ref([
    {
        bgImgUrl: '/general_images/card1.png',
        title: 'Financials',
        description: 'current services of our financials department',
        gridWidth: 3,
        available: true,
        routerLink: '/financials',
        items: [
            [
                {
                    title: 'Payment Terms',
                    available: false
                },
                {
                    title: 'Payment History',
                    available: false
                },
                {
                    title: 'Revenue Histroy',
                    available: true
                },
                {
                    title: 'Credit Limit',
                    available: false
                },
                {
                    title: 'Outstanding Debits',
                    available: false
                },
            ]
        ]
    },
    {
        bgImgUrl: '/general_images/card2.png',
        title: 'Warehouse',
        description: 'current services of our warehouse department',
        gridWidth: 3,
        available: true,
        routerLink: '/warehouse',
        items: [
            [
                {
                    title: 'Open Orders',
                    available: false
                },
                {
                    title: 'Lead Times',
                    available: false
                },
                {
                    title: 'Sample Availability',
                    available: true 
                },
                {
                    title: 'Delivery Schedule',
                    available: false
                },
                {
                    title: 'Change RDD',
                    available: false
                },


                {
                    title: 'Track Delivery',
                    available: false
                },

            ],
            [
                {
                    title: 'Check IncoTerms',
                    available: false
                },
            ]
        ]
    },
    {
        bgImgUrl: '/general_images/card3.png',
        title: 'Technical Support',
        description: 'current services of our Support department',
        gridWidth: 3,
        available: true,
        routerLink: '/support',
        items: [
            [
                {
                    title: 'RMAs',
                    available: true
                },
                {
                    title: 'Tickets',
                    available: false
                },


                {
                    title: 'Request Online Test',
                    available: false
                },

                {
                    title: 'Osadl & Online Test',
                    available: false
                },
                {
                    title: 'Documentations',
                    available: false
                },
                {
                    title: 'Price / Watt',
                    available: false
                },

            ],
            [
                {
                    title: 'Courses & Training',
                    available: false
                },
            ]
        ]
    },
    {
        bgImgUrl: '/general_images/card5.png',
        title: 'Quality',
        description: 'current services of our Quality department',
        gridWidth: 3,
        available: false,
        routerLink: '',
        items: [
            [
                {
                    title: 'Statistics',
                    available: false
                },
                {
                    title: 'Certificates',
                    available: false
                },
            ]
        ]
    },
    {
        bgImgUrl: '/general_images/card4.png',
        title: 'Sales',
        description: 'current services of our Sales department',
        gridWidth: 4,
        available: true,
        routerLink: '/sales',
        items: [
            [
                {
                    title: 'Order backlog',
                    available: true
                },
                {
                    title: 'Lead Times',
                    available: false
                },


                {
                    title: 'Price Lists',
                    available: false
                },

                {
                    title: 'Power Pricer',
                    available: false
                },
                {
                    title: 'Request a Quote',
                    available: false
                },
                {
                    title: 'Forecasting tool',
                    available: false
                },

            ],
            [
                {
                    title: 'Opportunity management',
                    available: true
                },
                {
                    title: 'Project Registration',
                    available: false
                },
                {
                    title: 'Open deals & opps.',
                    available: false
                },
                {
                    title: 'Product configurator',
                    available: false
                },
                {
                    title: 'Special offers',
                    available: false
                },
                {
                    title: 'Sales statistics',
                    available: false
                },
            ]
        ]
    },
    {
        bgImgUrl: '/general_images/card6.png',
        title: 'Marketing',
        description: 'current services of our Marketing department',
        gridWidth: 4,
        available: false,
        routerLink: '',
        items: [
            [
                {
                    title: 'Marketing Budget',
                    available: false
                },
                {
                    title: 'Planned Campaigns',
                    available: false
                },


                {
                    title: 'Campaings Signup',
                    available: false
                },

                {
                    title: 'Upcoming Events',
                    available: false
                },
                {
                    title: 'Register Events',
                    available: false
                },
                {
                    title: 'Upload / Share',
                    available: false
                },

            ],

        ]
    },
    {
        bgImgUrl: '/general_images/card7.png',
        title: 'Legal',
        description: 'current services of our Legal department',
        gridWidth: 4,
        available: false,
        routerLink: '',
        items: [
            [
                {
                    title: 'Contracts',
                    available: false
                },
                {
                    title: 'Dual Use',
                    available: false
                },


                {
                    title: 'Export Control',
                    available: false
                },

                {
                    title: 'NDA',
                    available: false
                },

            ],

        ]
    },
])
</script>
<style>
.Card-Button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.Card-Button .p-button-label {
    flex: 0;
}
</style>
<style scoped>
.disabled-card {
    pointer-events: none;
    opacity: 0.6;
}
</style>
