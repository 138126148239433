<template>
  <div class="mainContent" style="min-height:400px;">
    <div class="flex flex-column w-full mb-3">
      <Breadcrumb :model="breadcrumbs" style="background: transparent !important; ">
        <template #item="{ item }">
          <routerLink v-if="item.route" v-slot="{ navigate }" :to="item.route" custom>
            <a @click="navigate">
              <span>{{ item.label }}</span>
            </a>
          </routerLink>
        </template>
      </Breadcrumb>
    </div>
    <ConDataTable tableId="OrderList" title="Samples Availability" :columns="columns" class="w-full mb-5"
      filterDisplay="menu" :rowsPerPageOptions="[10, 20, 50]" :onReload="onReload" ref="tableRef">
      <!-- :createActionMenu="createVisitActionMenu"> -->
      <template #availability="col">
        <Column :field="col.field" :header="col.header" dataType="text" :sortable="col.sortable">
          <template #body="field">
            <i class="pi pi-check" style=" color: rgb(23, 201, 100)"
              v-if="field.data.availability == SamplesAvailabilityStatus.IN_STOCK"></i>
            <i class="pi pi-times" style="color: rgb(218, 50, 8)" v-else></i>
          </template>
        </Column>
      </template>

    </ConDataTable>
  </div>
</template>
<script setup lang="ts">
import { ConDataTable, type SieveCallback } from '@congatec/primevue-components-lib';
import { ref } from 'vue';
import Breadcrumb from 'primevue/breadcrumb';
import { type CalendarProps } from '@congatec/primevue-components-lib';
import { useRouter } from 'vue-router';
import { to } from '@congatec/primevue-components-lib';
import { useCompanyStore } from '@/stores/company-store';
import { sleepAsync } from '@/shared/sleep';
import { SamplesAvailabilityStatus, SapService } from '@/apis/ceresep';
import Column from 'primevue/column';

const columns = ref([
  {
    field: "name",
    header: "Name",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "materialId",
    header: "Material",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "availability",
    header: "Available",
    dataType: "text",
    sortable: true,
  },
])

const breadcrumbs = [
  { label: "PartnerPortal-Warehouse", route: "/warehouse" },
  { label: "Samples Availability", route: "/samples-availability" },
]
const companyStore = useCompanyStore();

companyStore.$subscribe(() => {
  tableRef.value?.forceReload();
});

const tableRef = ref<null | typeof ConDataTable>(null);


const onReload = async (filters: string, sorts: string, page: number, rows: number) => {
  while (companyStore.selectedCompany == undefined) {
    console.info("Waiting for company id selection...");
    await sleepAsync(100);
  }
  var res = await SapService
    .getApiV1SapSamplesAvailability(companyStore.selectedCompany?.id || 0, filters, sorts, page, rows);
  return {
    data: res.data,
    totalCount: res.totalCount
  }
}

</script>
<style scoped>
a {
  cursor: pointer;
}
</style>
