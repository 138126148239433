/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContactsResponse } from '../models/ContactsResponse';
import type { SendEmailRequest } from '../models/SendEmailRequest';
import type { SendEmailResponse } from '../models/SendEmailResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EmailService {

    /**
     * @param companyId 
     * @returns ContactsResponse Success
     * @throws ApiError
     */
    public static getApiV1EmailContacts(
companyId: number,
): CancelablePromise<ContactsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Email/{companyId}/Contacts',
            path: {
                'companyId': companyId,
            },
        });
    }

    /**
     * @param companyId 
     * @param requestBody 
     * @returns SendEmailResponse Success
     * @throws ApiError
     */
    public static postApiV1EmailSendEmail(
companyId: number,
requestBody?: SendEmailRequest,
): CancelablePromise<SendEmailResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Email/{companyId}/SendEmail',
            path: {
                'companyId': companyId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
