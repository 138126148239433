<template>
  <div class="flex grid w-full">
    <div class="w-full">
      <img :src="props.pageBanner" style="width:100% !important; height: 475.75px" />
    </div>
    <div class="flex flex-grid mainContent">
      <div class="flex flex-column w-full mb-3">
        <Breadcrumb :home="{ label: 'Home', command:to( '/') }"
          :model="[{ label: props.breadcrumb.label, command:to(props.breadcrumb.route) }]"
          style="background: transparent !important; "></Breadcrumb>
      </div>
      <div class="flex flex-row grid justify-content-center align-items-center w-full sm:min-w-screen gap-3"
        id="cardContainer">

        <Card class="m-1 sm:col-12 col-6 Page-Card " :class=" {'disabled-card': card.route == ''}" v-bind:key="card.title" v-for="card in props.cards" :disabled="card.disabled">
          <template #header>
            <div class="card-bck-img">
              <img class="card-bck-img" :src="card.icon">
            </div>
          </template>
          <template #title>
            <h2 style="margin:0 !important; margin-top: 1rem; color:var(--primary-color); font-size: 1.25rem;">
              {{ card.title }}
            </h2>
          </template>
          <template #content>
            <div class="flex grid justify-content-left pt-4 pl-5 pr-5"
              style="text-align: center; font-size: 1.5rem !important; line-height: 1.5 !important; justify-content: center;">
              <p class="w-full">
                {{ card.content }}
              </p>
              <Button class="mt-6" icon="pi pi-arrow-right" label="more" @click="router.push(card.route)"></Button>
            </div>

          </template>
        </Card>
      </div>
    </div>

  </div>
</template>
<script setup lang="ts">
import Card from 'primevue/card';
import Button from 'primevue/button';
import Breadcrumb from 'primevue/breadcrumb';
import {to} from '@congatec/primevue-components-lib';
import { useRouter } from 'vue-router';
const router = useRouter();

const props = defineProps<{
  pageBanner: string,
  breadcrumb: {
    label: string,
    route: string
  },
  cards: Array<CardContent>
}>();

type CardContent = {
  icon: string,
  title: string,
  content: string,
  route: string,
  disabled?: boolean
}

</script>
<style scoped>
.mainContent {
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;
  flex-direction: column;
  width: 100% !important;
  max-width: 1170px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: center !important;
  padding: 0 !important;
}

.grid {
  margin: 0;
}

a {
  color: var(--primary-color)
}

p {
  font-size: 1rem !important;
  font-weight: normal !important;
  line-height: 1.5 !important;
  color: #46464b !important;
  font-family: Arial, Helvetica, sans-serif !important;
}

.Page-Card {
  cursor: pointer;
  padding: 0%;
  height: 20em;
  max-width: 360px;
  width: 100%;
  max-height: 433px;
  min-height: 433px;
  height: 100%;
  border-radius: 14px 14px 0 0;
  overflow: hidden;
  border-spacing: 0;
  padding: 0;
  font-family: "Adelle W02 SemiBold", "Times New Roman", Times, serif !important;
}

.p-card {
  box-shadow: none;

}

.card-bck-img {
  background-position: center;
  height: 85px;
  background-repeat: no-repeat;
}

#cardContainer {
  /* gap: 30px !important; */
  padding-top: 40px;
  min-width: 100vw !important;
  /* min-width: 1170px !important; */
}

@media (min-width:1170px) {
  #cardContainer {
    min-width: 1170px !important;
  }

}

.p-card .p-card-content {
  padding: 0 !important;

}

.p-card-body {
  padding: 0 !important;

}

.disabled-card {
    pointer-events: none;
    opacity: 0.6;
}
</style>