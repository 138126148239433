import HomeView from "@/components/home/views/HomeView.vue";
import { ConSso } from "@congatec/primevue-components-lib";
import { createRouter, createWebHistory } from "vue-router";
import PageComponent from "@/components/2ndLevelPage/PageComponent.vue";
import TableComponentPage from "@/components/3rdLevelPages/TableComponentPage.vue";
import SamplesAvailabilityTable from "@/components/3rdLevelPages/SamplesAvailabilityTable.vue";
import ContactPage from "@/components/contact/ContactPage.vue";
import { OpportunityService, SapService } from "@/apis/ceresep";
import { useCompanyStore } from "@/stores/company-store";
import { calculateOverdue, formatDate, formatDecimal, invoiceMapper, mapSapValue, orderBacklogMapper } from "@/text/sap";
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "home",
      component: HomeView,
    },
    {
      path: "/silent-sso",
      name: "silent-sso",
      component: ConSso,
    },
    {
      path: "/contact",
      name: "contact",
      component: ContactPage,
    },
    {
      path: "/financials",
      name: "financials",
      component: PageComponent,
      props: {
        pageBanner: "/financials_icons/ph-pp-header-financials.jpg",
        breadcrumb: { label: "PartnerPortal-Financials", route: "/financials" },
        cards: [
          {
            icon: "/financials_icons/hexicon-information.svg",
            title: "Payment terms",
            content: "check incoming payments of your invoices",
            route: "",
          },
          {
            icon: "/financials_icons/hexicon-calendar-2.svg",
            title: "Payment history",
            content: "check payments of past invoices",
            route: "",
          },
          {
            icon: "/financials_icons/hexicon-change.svg",
            title: "Credit Limit",
            content: "check the current status of your Credit Limit",
            route: "",
          },
          {
            icon: "/financials_icons/hexicon-clock.svg",
            title: "Outstanding debits",
            content: "check overdue payments related to invoices",
            route: "",
          },
          {
            icon: "/financials_icons/hexicon-exchange-ideas.svg",
            title: "Revenue history",
            content: "check your revenue history with congatec",
            route: "/invoices",
          },
        ],
      },
    },
    {
      path: "/sales",
      name: "Sales",
      component: PageComponent,
      props: {
        pageBanner: "/sales/sales_placeholder_image.jpg",
        breadcrumb: { label: "PartnerPortal-Sales", route: "/sales" },
        cards: [
          {
            icon: "/financials_icons/hexicon-information.svg",
            title: "Order Backlog",
            content: "check your open orders here",
            route: "/orders",
          },
          {
            icon: "/financials_icons/hexicon-calendar-2.svg",
            title: "Open deals & opps",
            content: "check open opportunities",
            route: "",
          },
          {
            icon: "/financials_icons/hexicon-change.svg",
            title: "Price Lists",
            content: "find current product price list",
            route: "",
          },
          {
            icon: "/financials_icons/hexicon-clock.svg",
            title: "Power Pricer",
            content: "get a budgetary offer",
            route: "",
          },
          {
            icon: "/financials_icons/hexicon-exchange-ideas.svg",
            title: "Request a Quote",
            content: "resquest a formal offer",
            route: "",
          },
          {
            icon: "/financials_icons/hexicon-exchange-ideas.svg",
            title: "Product configurator",
            content: "estimate your custom product",
            route: "",
          },
          {
            icon: "/financials_icons/hexicon-exchange-ideas.svg",
            title: "Opportunity management",
            content: "update status of your opportutinies",
            route: "/opp",
          },
          {
            icon: "/financials_icons/hexicon-exchange-ideas.svg",
            title: "Project Registration",
            content: "register a new project",
            route: "",
          },
          {
            icon: "/financials_icons/hexicon-exchange-ideas.svg",
            title: "Lead Times",
            content: "check current product Lead Times",
            route: "",
          },
          {
            icon: "/financials_icons/hexicon-exchange-ideas.svg",
            title: "Forecasting tool",
            content: "update forecast of your opportunities",
            route: "",
          },
          {
            icon: "/financials_icons/hexicon-exchange-ideas.svg",
            title: "Special offers",
            content: "listed products at special prices",
            route: "",
          },
          {
            icon: "/financials_icons/hexicon-exchange-ideas.svg",
            title: "Sales statistics",
            content: "Data analysis",
            route: "",
          },
        ],
      },
    },
    {
      path: "/support",
      name: "Technical Support",
      component: PageComponent,
      props: {
        pageBanner: "/sales/sales_placeholder_image.jpg",
        breadcrumb: { label: "PartnerPortal-Technical Support", route: "/support" },
        cards: [
          {
            icon: "/financials_icons/hexicon-information.svg",
            title: "Return Merchandise Authorization",
            content: "check status of open RMAs",
            route: "/rmas",
          },
          {
            icon: "/financials_icons/hexicon-calendar-2.svg",
            title: "Tickets",
            content: "check the status of your open tickets and open new ones",
            route: "",
          },
          {
            icon: "/financials_icons/hexicon-change.svg",
            title: "Tech. Documentation",
            content: "download and check all technical documentation related to congatec's products",
            route: "",
          },
          {
            icon: "/financials_icons/hexicon-clock.svg",
            title: "Request Onsite Test",
            content: "create your Online Test request here",
            route: "",
          },
          {
            icon: "/financials_icons/hexicon-exchange-ideas.svg",
            title: "Osadl & Online tests",
            content: "create your Osadl Test request here",
            route: "",
          },
          {
            icon: "/financials_icons/hexicon-exchange-ideas.svg",
            title: "Price per Watt",
            content: "check global market price",
            route: "",
          },
          {
            icon: "/financials_icons/hexicon-exchange-ideas.svg",
            title: "Courses & Training",
            content: "check and sign up for upcoming courses & training",
            route: "",
          },
        ],
      },
    },
    {
      path: "/warehouse",
      name: "WareHouse",
      component: PageComponent,
      props: {
        pageBanner: "/warehouse/header.png",
        breadcrumb: { label: "PartnerPortal-Technical Support", route: "/support" },
        cards: [
          {
            icon: "/financials_icons/hexicon-information.svg",
            title: "Open Orders",
            content: "Check Open Orders",
            route: "",
          },
          {
            icon: "/financials_icons/hexicon-calendar-2.svg",
            title: "Lead Times",
            content: "Check the current lead times",
            route: "",
          },
          {
            icon: "/financials_icons/hexicon-change.svg",
            title: "Sample Availability",
            content: "Check current sample availability",
            route: "/sample-availability",
          },
          {
            icon: "/financials_icons/hexicon-clock.svg",
            title: "Delivery Schedule",
            content: "",
            route: "",
          },
          {
            icon: "/financials_icons/hexicon-exchange-ideas.svg",
            title: "Change RDD",
            content: "",
            route: "",
          },
          {
            icon: "/financials_icons/hexicon-exchange-ideas.svg",
            title: "Track Delivery",
            content: "",
            route: "",
          },
          {
            icon: "/financials_icons/hexicon-exchange-ideas.svg",
            title: "Check IncoTerms",
            content: "",
            route: "",
          },
        ]
      }
    },
    {
      path: "/sample-availability",
      name: "SamplesAvailability",
      component: SamplesAvailabilityTable
    },
    {
      path: "/orders",
      name: "OrderBacklog",
      component: TableComponentPage,
      props: {
        dataTableTitle: "Order Backlog",
        breadcrumb: [
          { label: "PartnerPortal-Sales", route: "/sales" },
          { label: "Order Backlog", route: "/orders" },
        ],
        expansionKey: "toItem",
        tableColumns: [
          {
            expander: true,
            field: "expansion",
            header: "",
          },
          {
            selectionMode: "multiple",
            field: "any",
          },
          {
            field: "salesOrder",
            header: "Order",
            dataType: "numeric",
            isFiltered: true,
            sortable: true,
            path: "to_SalesOrder/",
          },
          // {
          //   field: "salesOrderType",
          //   header: "Order Type",
          //   dataType: "text",
          //   isFiltered: true,
          //   sortable: true,
          //   path: "to_SalesOrder/",
          // },
          {
            field: "overallTotalDeliveryStatus",
            header: "Delivery Status",
            dataType: "text",
            isFiltered: true,
            sortable: true,
            path: "to_SalesOrder/",
          },
          {
            field: "requestedDeliveryDate",
            header: "Requested Delivery Date",
            onFormat: formatDate,
            dataType: "date",
            isFiltered: true,
            sortable: true,
            path: "to_SalesOrder/",
          },
          {
            field: "confirmedDeliveryDate",
            header: "Delivery Date in Clarification",
            onFormat: formatDate,
            dataType: "date",
            isFiltered: true,
            sortable: true,
            path: "to_SalesOrder/",
          },
          {
            field: "purchaseOrderByCustomer",
            header: "Customer",
            dataType: "text",
            isFiltered: true,
            sortable: true,
            path: "to_SalesOrder/",
          },
          /*{
            field: "customerPaymentTerms",
            header: "Payment Terms",
            dataType: "text",
            isFiltered: true,
            sortable: true,
            path: "to_SalesOrder/",
          },*/
          {
            field: "totalNetAmount",
            header: "Total Net Amount",
            dataType: "numeric",
            isFiltered: true,
            sortable: true,
            path: "to_SalesOrder/",
          },
          {
            field: "transactionCurrency",
            header: "Transaction Currency",
            dataType: "text",
            isFiltered: false,
            sortable: false,
            path: "to_SalesOrder/",
          },
        ],
        tableExpansionColumns: [
          /*{
            field: "salesOrderItem",
            header: "Sales Order Item",
            dataType: "text",
          },
          {
            field: "SalesOrderItemText",
            header: "Sales Order Item Text",
            dataType: "text",
          },*/
          {
            field: "material",
            header: "Material",
            dataType: "text",
          },
          {
            field: "requestedQuantity",
            header: "Requested Quantity",
            dataType: "text",
          },
          {
            field: "netAmount",
            header: "NetAmount",
            dataType: "text",
          },
          {
            field: "deliveryStatus",
            header: "Delivery Status",
            dataType: "text",
          },
          /*{
            field: "materialByCustomer",
            header: "Material By Customer",
            dataType: "text",
          },
          {
            field: "pricingReferenceMaterial",
            header: "Pricing Reference Material",
            dataType: "text",
          },
          {
            field: "customerPaymentTerms",
            header: "Customer Payment Terms",
            dataType: "text",
          },*/
        ],
        fetchDataAsync: async function fetchOrderDataAsync(
          filters?: string,
          sorts?: string,
          page?: number,
          rows?: number
        ): Promise<any> {
          const company = useCompanyStore();
          // isLoading.value = true;
          // FIXME get CompanyId from user
          const res = await SapService.getApiV1SapSalesOrder(
            company.selectedCompany?.id || 0,
            filters,
            sorts,
            page,
            rows,
            false
          );
          res.salesOrders?.forEach(mapSapValue(orderBacklogMapper));
          res.salesOrders?.forEach((x: any) => {
            console.log(x.toItem);
            x.toItem.forEach(mapSapValue(orderBacklogMapper));
            console.log(x.toItem);
          });
          //console.log("Res:", res)
          // const res = {
          //     totalCount: 3,
          //     orders: [{ order: "order1", deliveryTime: new Date("2023-09-07T09:00:00"), products: [{ productNumber: "pn1", productName: "Name1", price: usdFormatter.format(49.99) }, { productNumber: "pn2", productName: "Name2", price: usdFormatter.format(119.99) }, { productNumber: "pn3", productName: "Name3", price: usdFormatter.format(1337.99) },] }, { order: "order2", deliveryTime: new Date("2023-09-07T09:00:00"), products: [{ productNumber: "pn4", productName: "Name4", price: usdFormatter.format(49.99) }, { productNumber: "pn5", productName: "Name5", price: usdFormatter.format(119.99) }, { productNumber: "pn6", productName: "Name6", price: usdFormatter.format(1337.99) },] }, { order: "order3", deliveryTime: new Date("2023-09-07T09:00:00"), products: [{ productNumber: "pn7", productName: "Name7", price: usdFormatter.format(49.99) }, { productNumber: "pn8", productName: "Name8", price: usdFormatter.format(119.99) }, { productNumber: "pn9", productName: "Name9", price: usdFormatter.format(1337.99) },] },]
          // }
          // isLoading.value = false;
          return {
            totalCount: res.totalCount,
            data: res.salesOrders,
          };
        },
      },
    },
    {
      path: "/invoices",
      name: "Invoice",
      component: TableComponentPage,
      props: {
        dataTableTitle: "Invoices",
        breadcrumb: [
          { label: "PartnerPortal-Financials", route: "/financials" },
          { label: "Invoices", route: "/invoices" },
        ],
        expansionKey: "items",
        tableColumns: [
          {
            expander: true,
            field: 'expansion',
            header: ''
          },
          {
            selectionMode: 'multiple',
            field: 'any'
          },
          {
            field: "billingDocument",
            header: "Invoice Nr.",
            dataType: "text",
            isFiltered: true,
            sortable: true,
            path: "to_BillingDocument/"
          },
          {
            field: "overallBillingStatus",
            header: "Billing Status",
            dataType: "text",
            isFiltered: true,
            sortable: true,
            path: "to_BillingDocument/"
          },
          {
            field: "billingDocumentDate",
            header: "Invoice Date",
            dataType: "date",
            onFormat: formatDate,
            isFiltered: true,
            sortable: true,
            path: "to_BillingDocument/"
          },
          {
            field: "paymentUntil",
            header: "Payment until",
            dataType: "date",
            onFormat: formatDate,
            isFiltered: true,
            sortable: true,
            path: "to_BillingDocument/"
          },
          {
            field: "paymentUntil",
            header: "Payment Status",
            dataType: "text",
            onFormat: calculateOverdue,
            isFiltered: true,
            sortable: true,
            path: "to_BillingDocument/"
          },
          {
            field: "totalNetAmount",
            header: "Total Net Amount",
            dataType: "numeric",
            onFormat: formatDecimal,
            class: 'text-right',
            isFiltered: true,
            sortable: true,
            path: "to_BillingDocument/"
          },
          {
            field: "transactionCurrency",
            header: "Currency",
            dataType: "numeric",
            isFiltered: false,
            sortable: false,
            path: "to_BillingDocument/"
          },

          //   {
          //     field: "actionMenu",    
          //   }
        ],
        tableExpansionColumns: [
          {
            field: "material",
            header: "Material",
            dataType: "text",
          },
          {
            field: "billingQuantity",
            header: "Quantity",
            dataType: "text",
          },
          {
            field: "netAmount",
            header: "NetAmount",
            dataType: "numeric",
          },
          {
            field: "taxAmount",
            header: "Tax",
            dataType: "numeric",
          },
          /*{
            field: "transactionCurrency",
            header: "Currency",
            dataType: "text",
          },*/
        ],
        fetchDataAsync: async function fetchOrderDataAsync(filters?: string, sorts?: string, page?: number, rows?: number): Promise<any> {
          const company = useCompanyStore();
          // FIXME get CompanyId from user
          const res = await SapService.getApiV1SapInvoice(company.selectedCompany?.id!, filters, sorts, page, rows);
          res.invoices?.forEach(mapSapValue(invoiceMapper));

          return {
            totalCount: res.totalCount,
            data: res.invoices,
          }
        }
      },
    },
    {
      path: "/rmas",
      name: "Rmas",
      component: TableComponentPage,
      props: {
        dataTableTitle: "Return Merchandise Authorization",
        breadcrumb: [
          { label: "PartnerPortal-TechnicalSupport", route: "/support" },
          { label: "RMAs", route: "/rmas" },
        ],
        expansionKey: "toItem",
        tableColumns: [
          {
            expander: true,
            field: 'expansion',
            header: ''
          },
          {
            selectionMode: 'multiple',
            field: 'any'
          },
          {
            field: "salesOrder",
            header: "RMA",
            dataType: "numeric",
            isFiltered: true,
            sortable: true,
            path: "to_SalesOrder/"
          },
          {
            field: "salesOrderType",
            header: "Order Type",
            dataType: "text",
            isFiltered: true,
            sortable: true,
            path: "to_SalesOrder/"
          },
          {
            field: "overallTotalDeliveryStatus",
            header: "Delivery Status",
            dataType: "text",
            isFiltered: true,
            sortable: true,
            path: "to_SalesOrder/"
          },
          {
            field: "requestedDeliveryDate",
            header: "Requested Delivery Date",
            dataType: "date",
            onFormat: formatDate,
            isFiltered: true,
            sortable: true,
            path: "to_SalesOrder/"
          },
          {
            field: "purchaseOrderByCustomer",
            header: "Customer",
            dataType: "text",
            isFiltered: true,
            sortable: true,
            path: "to_SalesOrder/"
          },
          /*{
            field: "customerPaymentTerms",
            header: "Payment Terms",
            dataType: "text",
            isFiltered: true,
            sortable: true,
            path: "to_SalesOrder/"
          },*/
          {
            field: "totalNetAmount",
            header: "Total Net Amount",
            dataType: "numeric",
            isFiltered: true,
            sortable: true,
            path: "to_SalesOrder/"
          },
          {
            field: "transactionCurrency",
            header: "Transaction Currency",
            dataType: "text",
            isFiltered: false,
            sortable: false,
            path: "to_SalesOrder/"
          },

          //   {
          //     field: "actionMenu",    
          //   }
        ],
        tableExpansionColumns: [
          /*{
            field: "salesOrderItem",
            header: "Item",
            dataType: "text",
          },
          {
            field: "SalesOrderItemText",
            header: "Sales Order Item Text",
            dataType: "text",
          },*/
          {
            field: "material",
            header: "Material",
            dataType: "text",
          },
          {
            field: "requestedQuantity",
            header: "Quantity",
            dataType: "text",
          },
          {
            field: "netAmount",
            header: "NetAmount",
            dataType: "text",
          },
          {
            field: "deliveryStatus",
            header: "Delivery Status",
            dataType: "text",
          },
          /*{
            field: "materialByCustomer",
            header: "Material By Customer",
            dataType: "text",
          },
          {
            field: "pricingReferenceMaterial",
            header: "Pricing Reference Material",
            dataType: "text",
          },
          {
            field: "customerPaymentTerms",
            header: "Customer Payment Terms",
            dataType: "text",
          },*/
        ],
        fetchDataAsync: async function fetchOrderDataAsync(filters?: string, sorts?: string, page?: number, rows?: number): Promise<any> {
          const company = useCompanyStore();
          //isLoading.value = true;
          // FIXME get CompanyId from user
          const res = await SapService.getApiV1SapSalesOrder(company.selectedCompany?.id!, filters, sorts, page, rows, true);
          res.salesOrders?.forEach(mapSapValue(orderBacklogMapper));
          res.salesOrders?.forEach((x: any) => {
            x.toItem.forEach(mapSapValue(orderBacklogMapper));
          });
          //console.log("Res:", res)
          // const res = {
          //     totalCount: 3,
          //     orders: [{ order: "order1", deliveryTime: new Date("2023-09-07T09:00:00"), products: [{ productNumber: "pn1", productName: "Name1", price: usdFormatter.format(49.99) }, { productNumber: "pn2", productName: "Name2", price: usdFormatter.format(119.99) }, { productNumber: "pn3", productName: "Name3", price: usdFormatter.format(1337.99) },] }, { order: "order2", deliveryTime: new Date("2023-09-07T09:00:00"), products: [{ productNumber: "pn4", productName: "Name4", price: usdFormatter.format(49.99) }, { productNumber: "pn5", productName: "Name5", price: usdFormatter.format(119.99) }, { productNumber: "pn6", productName: "Name6", price: usdFormatter.format(1337.99) },] }, { order: "order3", deliveryTime: new Date("2023-09-07T09:00:00"), products: [{ productNumber: "pn7", productName: "Name7", price: usdFormatter.format(49.99) }, { productNumber: "pn8", productName: "Name8", price: usdFormatter.format(119.99) }, { productNumber: "pn9", productName: "Name9", price: usdFormatter.format(1337.99) },] },]
          // }
          //isLoading.value = false;
          return {
            totalCount: res.totalCount,
            data: res.salesOrders,
          }
        }
      },
    },
    {
      path: "/opp",
      name: "Opportunity Forecast",
      component: TableComponentPage,
      props: {
        calendarProps: null,
        dataTableTitle: "Opportunity Forecast",
        breadcrumb: [
          { label: "PartnerPortal-Sales", route: "/sales" },
          { label: "Opportunity Forecast", route: "/opp" },
        ],
        expansionKey: "forecast",
        tableColumns: [
          {
            expander: true,
            field: 'expansion',
            header: ''
          },
          {
            selectionMode: 'multiple',
            field: 'any'
          },
          {
            field: "name",
            header: "Opportunity Name",
            dataType: "text",
            isFiltered: false,
            sortable: false,
          },
          {
            field: "stage",
            header: "Stage/Level",
            dataType: "text",
            isFiltered: false,
            sortable: false,
          },
          {
            field: "status",
            header: "Status",
            dataType: "text",
            isFiltered: false,
            sortable: false,
          },

          //   {
          //     field: "actionMenu",    
          //   }
        ],
        tableExpansionColumns: [
          {
            field: "year",
            header: "Year",
            dataType: "text",
          },
          {
            field: "jan",
            header: "Jan",
            dataType: "text",
          },
          {
            field: "feb",
            header: "Feb",
            dataType: "text",
          },
          {
            field: "Mar",
            header: "Mar",
            dataType: "text",
          },
          {
            field: "apr",
            header: "Apr",
            dataType: "text",
          },
          {
            field: "may",
            header: "may",
            dataType: "text",
          },
          {
            field: "jun",
            header: "Jun",
            dataType: "text",
          },
          {
            field: "jul",
            header: "Jul",
            dataType: "text",
          },
          {
            field: "aug",
            header: "Aug",
            dataType: "text",
          },
          {
            field: "sep",
            header: "Sep",
            dataType: "text",
          },
          {
            field: "oct",
            header: "Oct",
            dataType: "text",
          },
          {
            field: "nov",
            header: "Nov",
            dataType: "text",
          },
          {
            field: "dec",
            header: "Dec",
            dataType: "text",
          },
        ],
        fetchDataAsync: async function fetchOpportunityForecastDataAsync(filters?: string, sorts?: string, page?: number, rows?: number): Promise<any> {
          const company = useCompanyStore();
          //isLoading.value = true;
          // FIXME get CompanyId from user
          const res = await OpportunityService.getApiV1OpportunityList(company.selectedCompany?.id, filters, sorts, page, rows);
          //console.log("Res:", res)
          // const res = {
          //     totalCount: 3,
          //     orders: [{ order: "order1", deliveryTime: new Date("2023-09-07T09:00:00"), products: [{ productNumber: "pn1", productName: "Name1", price: usdFormatter.format(49.99) }, { productNumber: "pn2", productName: "Name2", price: usdFormatter.format(119.99) }, { productNumber: "pn3", productName: "Name3", price: usdFormatter.format(1337.99) },] }, { order: "order2", deliveryTime: new Date("2023-09-07T09:00:00"), products: [{ productNumber: "pn4", productName: "Name4", price: usdFormatter.format(49.99) }, { productNumber: "pn5", productName: "Name5", price: usdFormatter.format(119.99) }, { productNumber: "pn6", productName: "Name6", price: usdFormatter.format(1337.99) },] }, { order: "order3", deliveryTime: new Date("2023-09-07T09:00:00"), products: [{ productNumber: "pn7", productName: "Name7", price: usdFormatter.format(49.99) }, { productNumber: "pn8", productName: "Name8", price: usdFormatter.format(119.99) }, { productNumber: "pn9", productName: "Name9", price: usdFormatter.format(1337.99) },] },]
          // }
          //isLoading.value = false;
          return {
            totalCount: res.total,
            data: res.opportunities,
          }
        }
      },
    },
  ],
});
export default router;
