<template>
  <div class="flex flex-column" style="  background-color: white !important;">
    <link rel="stylesheet" :href="theme" />
    <Toolbar class="toolbarClass">
      <template #center>
        
        <div class="flex flex-row ">
          <img src="/general_images/congatec-logo.png" class="lg:mr-6 cursor-pointer" style="width:125px; height: 94.4px; "
            @click="router.push('/')" />
          <div class="flex flex-wrap align-items-center lg:ml-8 " id="button-container">
            <div class="h-full sm:hidden md:hidden lg:flex flex-column hidden gap-3"
              >
              <div class="h-full sm:hidden md:hidden lg:flex hidden align-items-center justify-content-center gap-3">
                <label for="currentCompany" class="text-xl text-primary font-semibold">Your current Company:</label>
                <Dropdown class="w-6 h-3rem" id="currentCompany" v-model="selectedCompany" :options="companies"
                  optionLabel="companyName">
                  <template #value="slotProps">
                    <div v-if="slotProps.value" class="flex align-items-center">
                      <div>{{ slotProps.value.companyName }}</div>
                    </div>
                    <span v-else>
                      {{ slotProps.placeholder }}
                    </span>
                  </template>
                  <template #option="slotProps">
                    <div class="flex align-items-center h-full"
                      v-tooltip="'Information: \n ' + slotProps.option.street + ' ' + slotProps.option.number + '\n' + slotProps.option.postal + ' ' + slotProps.option.city"
                      type="text" placeholder="right">
                      <div>{{ slotProps.option.companyName }}</div>
                    </div>
                  </template>
                </Dropdown>
              </div>
              <div class="h-full sm:hidden md:hidden lg:flex flex-row hidden ">
                <!-- @mouseleave="(event) => clickProducts(event)" -->
                <Button label="Products" text class="navbar-button text-color-secondary" @click="toggleProductsMenu" @mouseover="(event) => showProductsMenu(event)" aria-haspopup="true" aria-controls="products_tmenu"></Button>
                <TieredMenu class="flex conganav" ref="productsMenu" id="products_tmenu" :model="productsMenuItems" @blur="() => { isProductsMenuVisible = false }" @before-show="hideAllMenus()" popup >
                  <template #start>
                    <span class="flex align-self-start sn_header subnav_wrapper">
                      Ecosystem 
                    </span>
                    <span class="flex align-self-end sn_header subnav_wrapper">
                      Quick Finder
                    </span>
                  </template>
                  <template #item="{ item }">
                    <a :href="item.url" class="p-menuitem-link" style="width:325px; text-decoration:none;">
                      <span :class="item.icon" />
                      <span class="ml-2">{{ item.label }}</span>
                    </a>
                  </template>
                </TieredMenu>

                <Button label="Services" text class="navbar-button text-color-secondary" @click="toggleServicesMenu" @mouseover="(event) => showServicesMenu(event)" aria-haspopup="true" aria-controls="servicesMenu_tmenu"></Button>
                <TieredMenu class="flex conganav" ref="servicesMenu" id="servicesMenu_tmenu" :model="servicesMenuItems" @blur="() => { isServicesMenuVisible = false }" @before-show="hideAllMenus()" popup >´
                  <template #start>
                    <span class="flex align-self-start sn_header subnav_wrapper"  style="width: 680px">
                      Services & Trainings
                    </span>
                  </template>
                </TieredMenu>

                <Button label="Industries" text class="navbar-button text-color-secondary" @click="toggleIndustriesMenu" @mouseover="(event) => showIndustriesMenu(event)" aria-haspopup="true" aria-controls="industriesMenu_tmenu"></Button>
                <TieredMenu class="flex conganav" ref="industriesMenu" id="industriesMenu_tmenu" :model="industriesMenuItems" @blur="() => { isIndustriesMenuVisible = false }" @before-show="hideAllMenus()" popup >
                  <template #start>
                    <span class="flex align-self-start sn_header subnav_wrapper"  style="width: 680px">
                      Industries
                    </span>
                  </template>
                </TieredMenu>
                <Button label="Knowledge Base" text class="navbar-button text-color-secondary" @click="toggleKnowledgeBaseMenu" @mouseover="(event) => showKnowledgeBaseMenu(event)" aria-haspopup="true" aria-controls="knwledgeBase_tmenu"></Button>
                <TieredMenu class="flex conganav" ref="knowledgeBaseMenu" id="knwledgeBase_tmenu" :model="knowledgeBaseMenuItems" @blur="() => { isKnowledgeBaseMenuVisible = false }" @before-show="hideAllMenus()" popup >
                  <template #start>
                    <span class="flex align-self-start sn_header subnav_wrapper" style="width: 680px">
                      Knowledge Base
                    </span>
                  </template>
                </TieredMenu>
                
              </div>

            </div>

            
          </div>
          <div class="flex-column h-full gap-6 sm:hidden md:hidden lg:flex hidden " style="margin-left:17rem;"
              >
              <div class="flex flex-row gap-5" style="align-items: flex-start; ">
                <div class="flex flex-row">
                  <a href="https://www.congatec.com/de/congatec/ueber-die-congatec/" style="text-decoration:none;">
                    <Button label="congatec" text class="text-color-secondary p-0 text-sm ml-1 mr-1"></Button>
                  </a>
                  <a href="https://www.congatec.com/de/congatec-support/" style="text-decoration:none;">
                    <Button text label="Support" class="text-color-secondary p-0 text-sm"></Button>
                  </a>
                  <a href="https://www.congatec.com/de/congatec/karriere-bei-der-congatec/karriere/" style="text-decoration:none;">
                    <Button text label="Career" class="text-color-secondary p-0 text-sm"></Button>
                  </a>
                  <Button text label="Logout" @click="logout()" class="text-color-secondary p-0 text-sm"></Button>
                </div>

                <!-- <Button text label="Language" class="text-color-secondary text-xs p-0"></Button> -->
                <Dropdown v-if="selectedLanguage.length > 0" class="flex text-color-secondary text-sm" v-model="selectedLanguage" :options="languages"
                  optionLabel="name" placeholder="Select a Language" unstyled />
              </div>
              <div class="flex flex-row">
                <!-- <Button icon="pi pi-search" text @click="toggleInputVisibility" class="stupidAssButton"></Button> -->
                <div class="button-container w-full">
                  <div class="input-container bg-white-alpha-30">
                    <AutoComplete v-model="selectedSearchItem" optionLabel="name" :class="{ 'InputText': true, 'visible': isInputVisible, }" style="height:34px"
                      v-if="isInputVisible" placeholder="Search" :suggestions="suggestions" @complete="search" @item-select="() => router.push(selectedSearchItem.link)"></AutoComplete>
                  </div>
                  <Button class="search-button bg-white-alpha-30 text-color-secondary mr-5" icon="pi pi-search" text
                    @click="toggleInputVisibility" style="min-height: 34px !important; padding:0"></Button>
                  <Button label="Contact" class="w-9"
                    style="min-height: 34px !important; color:white !important; padding:0; border-radius: 8px;" @click="router.push('/contact')"></Button>
                </div>
              </div>
            </div>
        </div>

      </template>
      <template #end>
        <div class="flex flex-row gap-2 m-4 lg:hidden">
          <Button text class="lg:hidden ml-8">
            <img src="/social_icons/con-globe.png" v-if="buttonClicked" />
            <img src="/social_icons/con-globe_orange.png" v-if="!buttonClicked" />
          </Button>

          <!-- <Button icon="pi pi-align-justify" id="optionsButton" text style="color: var(--primary-color) !important;"
            @click="toggleOverlayMenu" class="lg:hidden" /> -->
          <ToggleButton v-model="overlayMenuVisible" onIcon="pi pi-times" offIcon="pi pi-bars" id="optionsButton" text
            offLabel="" onLable="" style="color: var(--primary-color) !important" class="lg:hidden custom-toggle"
            @click="toggleOverlayMenu" />
        </div>

        <!-- <ConThemeSwitcher /> -->
        <!--    <ConUserButton /> -->
      </template>
    </Toolbar>
    <div class="flex align-items-center justify-content-center w-full mb-6">
      <RouterView class="flex flex-column w-11 lg:w-8 md:w-8">

      </RouterView>

    </div>

    <ul id="stickynav" class="md:hidden sm:hidden lg:flex flex-column hidden">
      <li>
        <a href="https://www.congatec.com/contact-icon">
          <div class="flex flex-row w-full">
            <span class="stickynav_arrow">
              <span class="flex justify-content-center align-items-center h-full min-w-full"
                style="background-color: var(--primary-color);  height:48.5px !important;">
                <p style="font-size: 1.25rem;">Linkedin</p>
              </span>

              <img src="/social_icons/linkedin.svg" alt="" width="35" height="50">
            </span>
          </div>

        </a>
      </li>
      <li>
        <a href="https://www.congatec.com/contact-icon">
          <span class="stickynav_arrow">
            <span class="flex justify-content-center align-items-center h-full min-w-full"
              style="background-color: var(--primary-color);  height:48.5px !important;">
              <p style="font-size: 1.25rem;">Youtube</p>
            </span>
            <img src="/social_icons/youtube.svg" alt="" width="35" height="50">
          </span>
        </a>
      </li>
      <li @click="router.push('/contact')">
        <a>
          <span class="stickynav_arrow">
            <span class="flex justify-content-center align-items-center h-full min-w-full"
              style="background-color: var(--primary-color); height:48.5px !important;">
              <p style="font-size: 1.25rem;">Contact</p>
            </span>
            <img src="/social_icons/contact.svg" alt="" width="35" height="50">
          </span>
        </a>
      </li>
    </ul>


  </div>

  <OverlayPanel ref="overlayMenu" class="w-full fixed"
    style="min-height:1150px; margin-top: 136px; background-color: rgba(70,70,75,0.9); border-radius: 0;">
    <div class="flex flex-column w-full h-full align-items-center">
      <span class="p-input-icon-right w-6">
        <i class="pi pi-search" />
        <InputText placeholder="Search"
          class="bg-black-alpha-10 border-noround border-white-alpha-40 w-full text-white-alpha-70" />
      </span>
      <div class="flex flex-column ">
        <Button label="Products" text class="text-3xl text-white"></Button>
        <Button label="Services" text class="text-3xl text-white"></Button>
        <Button label="Industries" text class="text-3xl text-white"></Button>
        <Button label="Knowledge Base" text class="text-3xl text-white"></Button>
        <Button label="congatec" text class="text-3xl text-white"></Button>
        <Button label="Support" text class="text-3xl text-white"></Button>
        <Button label="Career" text class="text-3xl text-white"></Button>
        <Button label="Login" text class="text-3xl text-white"></Button>
      </div>

    </div>
  </OverlayPanel>
  <FooterBar></FooterBar>
</template>
<script setup lang="ts">
import { RouterView } from "vue-router";
import {
  useThemeStore,
  useBreadCrumbStore
} from "@congatec/primevue-components-lib";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import "../node_modules/@congatec/primevue-components-lib/dist/lib/style.css";

import { onMounted, ref, watch } from "vue";
import Toolbar from "primevue/toolbar";
import FooterBar from "./components/footer/FooterBar.vue";
import { useRouter } from "vue-router";
import OverlayPanel from "primevue/overlaypanel";
import ToggleButton from "primevue/togglebutton";
import Dropdown from "primevue/dropdown";
import { CompaniesService } from "./apis/ceresep";
import { useCompanyStore } from "./stores/company-store";
import AutoComplete from 'primevue/autocomplete';
import TieredMenu from 'primevue/tieredmenu';
import { useEventBroker } from "@congatec/eventbroker-lib";

const eventBroker = useEventBroker();
const selectedLanguage = ref("");
const languages = ref(['test']);
const companyStore = useCompanyStore()

const companies = ref<any | undefined>();
const selectedCompany = ref();
// [
//     { name: 'congatec Gmbh', street: 'Auwiesenstraße', number: '5', postal:'94469', city: 'Deggendorf' },
//     { name: 'Krickl Dev', street: 'Dukicweg', number: '69', postal:'95569', city: 'Boehmcity' },
//     { name: 'Wolfgram AG', street: 'Lexmidochstraße', number: '420', postal:'93420', city: 'Gammerhausen' },
// ]

const selectedSearchItem = ref();
const suggestions = ref<any[]>([]);
const searchItems = ref([
  {
    name: "Opportunity",
    link: "/opp"
  },
  {
    name: "Order Backlog",
    link: "/orders"
  },
  {
    name: "Return Merchandise Authorization (RMA)",
    link: "/rmas"
  },
  {
    name: "Revenue history",
    link: "/invoices"
  },
  {
    name: "Sample Availability",
    link: "/sample-availability"
  },
]);
const router = useRouter();
const breadCrumbStore = useBreadCrumbStore();

const productsMenuItems = ref([
  {
    label: 'COM-HPC Ecosystem',
    icon: 'pi pi-file',
    url: "https://www.congatec.com/de/ecosystems/com-hpc-ecosystem/",
  },
  {
    label: 'Server Class',
    icon: 'pi pi-file',
    url: "https://www.congatec.com/de/classes/server-class/",
  },
  {
    label: 'COM Express Ecosystem',
    icon: 'pi pi-file',
    url: "https://www.congatec.com/de/ecosystems/com-express-ecosystem/",
  },
  {
    label: 'Performance Class',
    icon: 'pi pi-file',
    url: "https://www.congatec.com/de/classes/performance-class/",
  },
  {
    label: 'SMARC Module Ecosystem',
    icon: 'pi pi-file',
    url: "https://www.congatec.com/de/produkte/smarc/",
  },
  {
    label: 'Low Power Class',
    icon: 'pi pi-file',
    url: "https://www.congatec.com/de/classes/low-power-class/",
  },
  {
    label: 'Qseven Ecosystem',
    icon: 'pi pi-file',
    url: "https://www.congatec.com/de/produkte/qseven/",
  },
  {
    label: 'Customized Search',
    icon: 'pi pi-file',
    url: "https://www.congatec.com/en/products/product-search/",
  },
  {
    label: 'SBC Ecosystem',
    icon: 'pi pi-file',
    url: "https://www.congatec.com/de/ecosystems/sbc-ecosystem/",
  },
  {
    label: 'Product Highlights',
    icon: 'pi pi-file',
    url: "https://productguide.congatec.com/view/769395850/",
  },
]);

const servicesMenuItems = ref([
  {
    label: 'Module Services',
    // icon: 'pi pi-file',
    url: "https://www.congatec.com/de/technologien/technical-services/",
  },
  {
    label: 'DESIGN-IN TRAINING - ARM',
    // icon: 'pi pi-file',
    url: "https://www.congatec.com/en/support/add-on-arm-training/",
  },
  {
    label: 'CARRIER BOARD DESIGN-IN TRAINING',
    // icon: 'pi pi-file',
    url: "https://www.congatec.com/en/designintraining/",
  }
]);

const industriesMenuItems = ref([
  {
    label: 'Infrastructure',
    // icon: 'pi pi-file',
    url: "https://www.congatec.com/de/industrien/infrastructure/",
  },
  {
    label: 'Industrial Automation',
    // icon: 'pi pi-file',
    url: "https://www.congatec.com/en/industries/industrial-automation/",
  },
  {
    label: 'Energy',
    // icon: 'pi pi-file',
    url: "https://www.congatec.com/en/industries/energy/",
  },
  {
    label: 'Test & Measurement Technology',
    // icon: 'pi pi-file',
    url: "https://www.congatec.com/en/industries/test-measurement-technology/",
  },
  {
    label: 'Robotics',
    // icon: 'pi pi-file',
    url: "https://www.congatec.com/en/industries/robotics/",
  },
  {
    label: 'Building Automation',
    // icon: 'pi pi-file',
    url: "https://www.congatec.com/en/industries/building-automation/",
  },
  {
    label: 'Entertainment & Professional Gaming',
    // icon: 'pi pi-file',
    url: "https://www.congatec.com/en/industries/entertainment-professional-gaming/",
  },
  {
    label: 'Point-of-Sale',
    // icon: 'pi pi-file',
    url: "https://www.congatec.com/en/industries/point-of-sale/",
  },
  {
    label: 'Medical Technology',
    // icon: 'pi pi-file',
    url: "https://www.congatec.com/en/industries/medical-technology/",
  },
  {
    label: 'Digital Signage',
    // icon: 'pi pi-file',
    url: "https://www.congatec.com/en/industries/digital-signage/",
  },
  {
    label: 'Transport Systems',
    // icon: 'pi pi-file',
    url: "https://www.congatec.com/en/industries/transport-systems/",
  },
  {
    label: 'IIoT & Industry 4.0',
    // icon: 'pi pi-file',
    url: "https://www.congatec.com/en/industries/iiot-industry-40/",
  },
]);

const knowledgeBaseMenuItems = ref([
  {
    label: 'Module Technologies',
    // icon: 'pi pi-file',
    url: "https://www.congatec.com/de/technologien/embedded-computer-on-modules/",
  },
  {
    label: 'Embedded Technologies',
    // icon: 'pi pi-file',
    url: "https://www.congatec.com/en/knowledge-base/embedded-technologies/",
  },
  {
    label: 'Whitepapers',
    // icon: 'pi pi-file',
    url: "https://www.congatec.com/en/support/whitepapers/",
  },
  {
    label: 'Application Notes',
    // icon: 'pi pi-file',
    url: "https://www.congatec.com/en/support/application-notes/",
  },
  {
    label: 'Webinars',
    // icon: 'pi pi-file',
    url: "https://www.congatec.com/en/support/webinars/",
  },
  {
    label: 'Videos',
    // icon: 'pi pi-file',
    url: "https://www.congatec.com/en/support/videos/aready/",
  },
  {
    label: 'Case Studies',
    // icon: 'pi pi-file',
    url: "https://www.congatec.com/en/support/case-studies/",
  },
]);


async function fetchCompaniesAsync() {
  selectedCompany.value = companyStore.selectedCompany
  companies.value = (await CompaniesService.getApiV1CompaniesGetCompanies()).companies
  if (companyStore.selectedCompany?.id == undefined) {
    companyStore.setCompany(companies.value[0])
    selectedCompany.value = companyStore.selectedCompany
  }
}

watch(selectedCompany, () => {
  companyStore.setCompany(selectedCompany.value);
});

onMounted(() => {
  breadCrumbStore.add({ label: "Sample", to: "/test" });
  fetchCompaniesAsync();
});


const overlayMenuVisible = ref(false);
const overlayMenu = ref();

const toggleOverlayMenu = (event: any) => {
  overlayMenu.value.toggle(event);
  console.log(overlayMenuVisible.value)

  // if(overlayMenuVisible.value){
  //   document.body.classList.add('no-scroll');
  // }else{
  //   document.body.classList.remove('no-scroll');
  // }

};

const buttonClicked = ref(false);

const isInputVisible = ref(false);

const toggleInputVisibility = () => {
  isInputVisible.value = !isInputVisible.value;
}

const themeStore = useThemeStore();
const theme = ref();
const useTheme = () => {
  theme.value = new URL("../node_modules/@congatec/primevue-components-lib/dist/lib/theme_light.css", import.meta.url).href;
}
useTheme();

themeStore.$subscribe(useTheme);

const search = (event: { query: string; }) => {
  suggestions.value = searchItems.value.filter((item) => item.name.toLowerCase().includes(event.query.toLowerCase()));
}

const clickProducts = (event: any) => {
  event.target.click();
};

const productsMenu = ref();
const isProductsMenuVisible = ref(false);
const toggleProductsMenu = (event: any) => {
  productsMenu.value.toggle(event);
  isProductsMenuVisible.value = !isProductsMenuVisible.value;
};

const showProductsMenu = (event: any) => {
  if(!isProductsMenuVisible.value)  {
    productsMenu.value.toggle(event);
    isProductsMenuVisible.value = true;
  }
};

const hideProductsMenu = () => {
  if(isProductsMenuVisible.value)  {
    productsMenu.value.hide();
    isProductsMenuVisible.value = false;
  }
};

const industriesMenu = ref();
const isIndustriesMenuVisible = ref(false);
const toggleIndustriesMenu = (event: any) => {
  industriesMenu.value.toggle(event);
  isIndustriesMenuVisible.value = !isIndustriesMenuVisible.value;
};

const showIndustriesMenu = (event: any) => {
  if(!isIndustriesMenuVisible.value)  {
    industriesMenu.value.toggle(event);
    isIndustriesMenuVisible.value = true;
  }
};

const hideIndustriesMenu = () => {
  if(isIndustriesMenuVisible.value)  {
    industriesMenu.value.hide();
    isIndustriesMenuVisible.value = false;
  }
};

const knowledgeBaseMenu = ref();
const isKnowledgeBaseMenuVisible = ref(false);
const toggleKnowledgeBaseMenu = (event: any) => {
  knowledgeBaseMenu.value.toggle(event);
  isKnowledgeBaseMenuVisible.value = !isKnowledgeBaseMenuVisible.value;
};

const showKnowledgeBaseMenu = (event: any) => {
  if(!isKnowledgeBaseMenuVisible.value)  {
    knowledgeBaseMenu.value.toggle(event);
    isKnowledgeBaseMenuVisible.value = true;
  }
};

const hideKnowledgeBaseMenu = () => {
  if(isKnowledgeBaseMenuVisible.value)  {
    knowledgeBaseMenu.value.hide();
    isKnowledgeBaseMenuVisible.value = false;
  }
};

const servicesMenu = ref();
const isServicesMenuVisible = ref(false);
const toggleServicesMenu = (event: any) => {
  servicesMenu.value.toggle(event);
  isServicesMenuVisible.value = !isServicesMenuVisible.value;
};

const showServicesMenu = (event: any) => {
  if(!isServicesMenuVisible.value)  {
    servicesMenu.value.toggle(event);
    isServicesMenuVisible.value = true;
  }
};

const hideServicesMenu = () => {
  if(isServicesMenuVisible.value)  {
    servicesMenu.value.hide();
    isServicesMenuVisible.value = false;
  }
};

const hideAllMenus = () => {
  hideProductsMenu();
  hideServicesMenu();
  hideIndustriesMenu();
  hideKnowledgeBaseMenu();
};

const logout = () => {
  // keycloak?.logout();
  console.log("broki:", eventBroker)
  eventBroker?.publish('logout');
}

</script>
<style scoped>
.navbar-button:hover::after {
  background-color: rgba(255, 255, 255, 0.4) !important;
  content: '' !important;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: -15px;
  border-bottom: 1px solid var(--primary-color) !important;
}


#button-container {
  display: flex;
  align-items: flex-end !important;
}

.InputText {
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: opacity 200s ease, height 200s ease !important;
}

.InputText.visible {
  opacity: 1;
  height: auto;
  overflow: visible;
}

.button-container {
  display: flex;
  align-items: center;
  position: relative;
  height: 34px;
}


.input-container {
  position: absolute;
  right: 0;
  left: -150px;
  top: 0;
  height: 34px;
}
</style>
<style>
body {
  margin: 0;
  padding: 0;
}

#currentCompany_list .p-dropdown-item {
  padding: 0rem 1rem !important;
  height: 3rem !important;
}

/* .p-tooltip {
  width: 100% !important;
} */

p {
  padding: 0;
  margin: 0;
}

.pi {
  font-size: 1rem !important;
}

.search-button>.pi {
  font-size: 2rem !important
}

.social_icons .pi {
  font-size: 2rem !important
}

#optionsButton .pi {
  font-size: 3rem !important
}

.toolbarClass {
  position: sticky !important;
  z-index: 1000 !important;
  top: 0 !important;
  border-bottom: 2px solid var(--primary-color);
  padding: 10px 0 !important;
  height: 136.4px;
  display:flex;
  justify-content: center;
  align-items: center;
}

.toolbarClass-sticky {
  position: relative
}

#stickynav {
  position: fixed;
  left: 0;
  top: 40vh;
  display: block;
  z-index: 100;
  transition: all 250ms ease-in;
  overflow: hidden;
  min-width: 60px;
  text-align: left;
}

#stickynav li {
  transition: transform 0.5s ease;
  transform: translateX(-145%);
  height: 60px;
}

/* #stickynav {
  margin: 0;
  padding: 0;
  list-style: none;
  display: none;
  gap: 1rem;
}  */



#stickynav a {
  display: flex;
  text-decoration: none;
}

#stickynav .stickynav_arrow {
  color: #FFF;
  white-space: nowrap;
  height: 50px;
  transition: transform 0.5s ease;
  transform: translateX();
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  justify-content: flex-start;
  width: 6rem;
}


#stickynav li:hover .stickynav_arrow {
  transform: translateX(95%);
}

.p-button {
  overflow: visible;
  border: none !important;
  /* background-color: #FFF; */
}

.no-scroll {
  overflow: hidden !important;
}

.p-icon {
  color: var(--primary-color)
}

.p-breadcrumb {
  border: none !important;
}

.conganav {
  width: 100%;
}

.p-tieredmenu {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-bottom: 2px solid #fc4c02;
  background: rgba(169,181,187,0.925);
  /* position: absolute; */
  position: fixed !important;
  left: 0;
  top: 136px !important;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.p-tieredmenu-root-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row wrap;
  width: 680px;
  right: 100%;
  z-index: 199;
}

/* .p-menuitem {

  
} */

.conganav .p-menuitem-link {
  transition: 0.5s;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10%;
  box-sizing: border-box;
  background: #95a3ab;
  font-size: 0.8rem;
  width: 200px;
  color: #f2f2f2;
  border-bottom: 3px solid transparent;
  min-height: 60px;
}

.conganav .p-menuitem-link:hover {
  transition: 0.5s;
  background: #fff;
  box-shadow: 1px 1px 10px rgba(0,0,0,0.4);
  color: #46464b;
  text-decoration: none;
  border-bottom: 3px solid #fc4c02;
}

.conganav .p-tieredmenu-start {
  display: flex;
  align-self: center;
  justify-content: space-between;
  width: 680px;;
}

.sn_header {
  font-weight: 500;
  /* display: block; */
  display: flex;
  
  color: #46464b;
  font-size: 1.25rem;
  text-decoration: none;
  border-bottom: 5px solid #46464b;
  font-family: "Adelle W02 SemiBold","Times New Roman",Times,serif;
  width: 325px;
}

.subnav_wrapper {
  /* width: 100%; */
  display: flex;
  align-items: start;
  justify-content: space-between;
  list-style-type: none;
  margin: 0;
  padding: 0;
}


</style>
