/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InvoiceListResponse } from '../models/InvoiceListResponse';
import type { SalesOrderListResponse } from '../models/SalesOrderListResponse';
import type { SamplesAvailabilityResponse } from '../models/SamplesAvailabilityResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SapService {

    /**
     * @param companyId 
     * @param sieveModelFilters 
     * @param sieveModelSorts 
     * @param sieveModelPage 
     * @param sieveModelPageSize 
     * @param rmaData 
     * @returns SalesOrderListResponse Success
     * @throws ApiError
     */
    public static getApiV1SapSalesOrder(
companyId?: number,
sieveModelFilters?: string,
sieveModelSorts?: string,
sieveModelPage?: number,
sieveModelPageSize?: number,
rmaData?: boolean,
): CancelablePromise<SalesOrderListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Sap/salesOrder',
            query: {
                'CompanyId': companyId,
                'sieveModel.Filters': sieveModelFilters,
                'sieveModel.Sorts': sieveModelSorts,
                'sieveModel.Page': sieveModelPage,
                'sieveModel.PageSize': sieveModelPageSize,
                'rmaData': rmaData,
            },
        });
    }

    /**
     * @param companyId 
     * @param sieveModelFilters 
     * @param sieveModelSorts 
     * @param sieveModelPage 
     * @param sieveModelPageSize 
     * @returns InvoiceListResponse Success
     * @throws ApiError
     */
    public static getApiV1SapInvoice(
companyId?: number,
sieveModelFilters?: string,
sieveModelSorts?: string,
sieveModelPage?: number,
sieveModelPageSize?: number,
): CancelablePromise<InvoiceListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Sap/invoice',
            query: {
                'CompanyId': companyId,
                'SieveModel.Filters': sieveModelFilters,
                'SieveModel.Sorts': sieveModelSorts,
                'SieveModel.Page': sieveModelPage,
                'SieveModel.PageSize': sieveModelPageSize,
            },
        });
    }

    /**
     * @param companyId 
     * @param sieveModelFilters 
     * @param sieveModelSorts 
     * @param sieveModelPage 
     * @param sieveModelPageSize 
     * @returns SamplesAvailabilityResponse Success
     * @throws ApiError
     */
    public static getApiV1SapSamplesAvailability(
companyId?: number,
sieveModelFilters?: string,
sieveModelSorts?: string,
sieveModelPage?: number,
sieveModelPageSize?: number,
): CancelablePromise<SamplesAvailabilityResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Sap/SamplesAvailability',
            query: {
                'CompanyId': companyId,
                'SieveModel.Filters': sieveModelFilters,
                'SieveModel.Sorts': sieveModelSorts,
                'SieveModel.Page': sieveModelPage,
                'SieveModel.PageSize': sieveModelPageSize,
            },
        });
    }

}
